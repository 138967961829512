import type { Step } from '@/types'
interface SiteFunctionalityType  {
  siteFunctionality: {
    isUploadModalOpen: boolean,
    signUpSteps: Step[]
  }
}
export const useSiteFunctionality = defineStore('siteFunctionality', {
 state: () => ({
   siteFunctionality: {
    isUploadModalOpen: false,
    signUpSteps: [
      {
        title: 'Your details',
        path: '/signup',
      },
      {
        title: 'Models, Stage & Size',
        path: '/signup/models',
      },
      {
        title: 'Industries',
        path: '/signup/industries',
      },
      {
        title: 'Geography',
        path: '/signup/geography',
      },
      {
        title: 'Tailored Memo Generation',
        path: '/signup/nuance',
      }
    ]
   } 
 }),
  getters: {
    getIsUploadModalOpen: (state:SiteFunctionalityType) => state.siteFunctionality.isUploadModalOpen,
    getSignUpSteps: (state:SiteFunctionalityType) => state.siteFunctionality.signUpSteps
  },
  actions: {
    setIsUploadModalOpen(newState:boolean) {
      this.siteFunctionality.isUploadModalOpen = newState
    },
  }
})